<template>
	<div class="BK">
		<div class="heri-container" @click="toAppStore">
			<img src="../assets/img/logo2.png" alt="" class="logo" />
			<div class="top-text">
				Conqurr your bucket list reads in <i>minutes per day</i>
			</div>
			<p class="second-text">Get Key ideas from nonfiction books each day</p>
			<div class="list">
				<div class="list-item" v-for="item in list" :key="item.id">
					<img :src="item.img" alt="" class="list-item-img">
					<p class="list-item-time">{{secondToMin(item.duration)}}</p>
				</div>
			</div>
			<div class="rating1"><i>124,000</i>Ratings <img class="rating-star"
					src="https://heardapp.soundems.com/ratings/star.png" alt="">
			</div>
			<div class="submit">GO TO APP STORE</div>
			<div class="rating2"><i>124,000</i>Ratings <img class="rating-star"
					src="https://heardapp.soundems.com/ratings/star.png" alt="">
			</div>
			<div class="footer">
				<div @click.stop="toUrl(item)" class="footer-item" v-for="(item,index) in list3" :key="index">
					{{item.text}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [{
					id: 1,
					img: 'https://heardapp.soundems.com/ratings/book1.png',
					duration: 540
				}, {
					id: 2,
					img: 'https://heardapp.soundems.com/ratings/book2.png',
					duration: 600
				}, {
					id: 3,
					img: 'https://heardapp.soundems.com/ratings/book3.png',
					duration: 480
				}, {
					id: 4,
					img: 'https://heardapp.soundems.com/ratings/book4.png',
					duration: 420
				}, {
					id: 5,
					img: 'https://heardapp.soundems.com/ratings/book5.png',
					duration: 660
				}, {
					id: 6,
					img: 'https://heardapp.soundems.com/ratings/book6.png',
					duration: 360
				}, {
					id: 7,
					img: 'https://heardapp.soundems.com/ratings/book7.png',
					duration: 300
				}],
				list3: [{
						id: 1,
						text: "@Heardly"
					},
					{
						id: 2,
						text: "Legal Notice"
					},
					{
						id: 4,
						text: "Terms of Service",
						url: '/service'
					},
					{
						id: 5,
						text: "Privacy Policies",
						url: '/privacy'
					}
				]
			}
		},
		methods: {
			secondToMin(time) {
				let timestr = parseInt(time)
				let min = parseInt(timestr / 60)
				let sec = timestr % 60
				min = min == 0 ? '0min' : min + 'min'
				return min
			},
			toUrl(item) {
				if (item.url) {
					this.$router.push({
						path: item.url
					})
				}
			},
			toAppStore() {
				usermaven("track", "ratingsClickBtn", {})
				FB.AppEvents.logEvent('go-to-store');
				fbq('track', 'Lead');
				window.location.href = 'https://heardly.onelink.me/tjIz/gotofb';
			}
		},
		mounted() {}
	}
</script>

<style lang="less" scoped>
	.BK {
		font-family: 'C-M';
		background: #F1F6F4;
		color: #162A41;

		.heri-container {}

		.logo {
			display: block;
			cursor: pointer;
			margin: 0 auto;
		}

		.top-text {
			font-weight: 600;
			text-align: center;

			i {
				color: #3645EC;
				font-style: normal;
			}
		}

		.second-text {
			text-align: center;
		}

		.list {
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.list-item {
				background: url(https://heardapp.soundems.com/ratings/quickMask.png) no-repeat left bottom;
				background-size: contain;
				text-align: center;
				position: relative;

				.list-item-img {}

				.list-item-time {
					font-weight: 300;
					position: absolute;
					left: 50%;
					bottom: 0;
					transform: translateX(-50%);
					color: #6B6B6B;
				}
			}
		}

		.rating1,
		.rating2 {
			color: #6B6B6B;
			display: flex;
			align-items: center;
			justify-content: center;

			i {
				font-style: normal;
				font-family: 'Helvetica';
			}
		}

		.submit {
			font-weight: 600;
			color: #fff;
			background: #3246F5;
			box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.17);
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.footer {
			color: #6B6B6B;
			display: flex;
			justify-content: space-between;

			.footer-item {
				cursor: pointer;
				position: relative;
				display: inline-block;
			}

			.footer-item:hover {
				color: rgb(3, 101, 242);
			}

			.footer-item::after {
				position: absolute;
				right: -10px;
				top: 50%;
				transform: translateY(-50%);
				height: 60%;
				width: 1px;
				background: #3A4649;
				content: '';
			}

			.footer-item:last-child::after {
				width: 0;
			}
		}
	}

	@media screen and (max-width: 829px) {
		.BK {
			min-height: 100vh;
			padding-top: 5rem;

			.logo {
				height: 2.666667rem;
				width: auto;
			}

			.top-text {
				margin: 4rem 4.166667rem 0;
				line-height: 2.5rem;
				font-size: 1.916667rem;
				color: #1D2A3F;
			}

			.second-text {
				margin: 1.75rem 4.166667rem 2.25rem;
				font-size: 1.25rem;
				line-height: 1.583333rem;
			}

			.list {
				width: 21rem;
				gap: 0.333333rem;
				margin-bottom: 0.833333rem;

				.list-item {
					width: 6.75rem;
					height: 6.5rem;
					margin-bottom: 2.666667rem;

					.list-item-img {
						width: 4.166667rem;
						height: 5.833333rem;
					}

					.list-item-time {
						bottom: -1.666667rem;
						font-size: 1.166667rem;
					}
				}

				.list-item:nth-child(7),
				.list-item:nth-child(6) {
					display: none;
				}
			}

			.rating1 {
				display: none;
			}

			.rating2 {
				font-size: 0.833333rem;
				line-height: 1.333333rem;
				margin-top: 0.833333rem;

				i {
					margin-right: 0.1rem;
				}

				.rating-star {
					width: 8rem;
					height: 1.333333rem;
					margin-left: 1.166667rem;
					margin-top: -0.2rem;
				}
			}

			.submit {
				margin: 0 auto;
				padding-top: 0.14rem;
				width: 25.833333rem;
				height: 3.666667rem;
				border-radius: 5rem;
				font-size: 1.583333rem;
				box-shadow: 0px 0.08rem 0px 0px rgba(0, 0, 0, 0.17);
				text-shadow: 0.06rem 0.08rem 0 rgba(0, 0, 0, 0.27);
			}

			.footer {
				padding: 0 2rem;
				margin-top: 4.833333rem;

				.footer-item {
					font-size: 0.916667rem;
				}
			}
		}
	}

	@media screen and (min-width: 830px) {
		.BK {
			padding-top: 56px;

			.heri-container {
				width: 560px;
			}

			.logo {
				width: 124px;
				height: auto;
			}

			.top-text {
				margin-top: 30px;
				line-height: 34px;
				font-size: 28px;
			}

			.second-text {
				margin-top: 18px;
				font-size: 19px;
				margin-bottom: 40px;
			}

			.list {
				width: 420px;
				gap: 5px;
				margin-bottom: 25px;

				.list-item {
					width: 100px;
					height: 97px;
					margin-bottom: 50px;

					.list-item-img {
						width: 62px;
						height: 85px;
					}

					.list-item-time {
						bottom: -25px;
						font-size: 18px;
					}
				}
			}

			.rating1 {
				font-size: 12px;
				line-height: 22px;

				i {
					margin-right: 4px;
				}

				.rating-star {
					width: 130px;
					height: 22px;
					margin-left: 15px;
					margin-top: -4px;
				}
			}

			.rating2 {
				display: none;
			}

			.submit {
				margin: 0 auto;
				margin-top: 32px;
				width: 370px;
				height: 50px;
				border-radius: 80px;
				font-size: 24px;
				text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.27);
			}

			.footer {
				margin-top: 180px;
				font-size: 13px;
				justify-content: center;
				padding-bottom: 40px;

				.footer-item {
					margin-right: 20px;
				}
			}
		}
	}
</style>